import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Quais são seus Planos?',
    paragraph: 'Somos uma Empresa focada em criação de desenvolvimento de planos empresariais digitais, criamos sua marca, sua identidade visual, seu site online, loja virtual, aplicativo e até mesmo fazemos seus anúncios para serem visualizados por seus possíveis clientes.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Desenvolvimento com agilidade.
                </div>
                <h3 className="mt-0 mb-12">
                  Criação de Site
                </h3>
                <p className="m-0">
                  Desenvolvimento de sites com linguagem totalmente atuais e responsivos (que se adequam para utilização em smartphones, tablets, computadores e notebooks) com layout moderno, prático e focado na geração de resultados necessários, visando o desenvolvimento de métricas e acompanhamento.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-01.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs  text-color-primary fw-600 tt-u mb-8">
                  Venda Online Sem Precisar de Funcionario
                </div>
                <h3 className="mt-0 mb-12">
                  Criação de Loja Virtual
                </h3>
                <p className="m-0">
                  Disponibilize produtos para vendas on-line. Temos a solução completa para uma loja virtual que visa atender a real necessidade do cliente. Algumas características do sistema: Fácil administração, segurança de dados, soluções de pagamento, otimização (SEO), cálculo de frete (Correios), e design personalizado.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Crie Seu Aplicativo
                </div>
                <h3 className="mt-0 mb-12">
                  Criação de Aplicativo
                </h3>
                <p className="m-0">
                  Criamos o Aplicativo que pode ser Atualizado com seu Site Atual, e acrescentar novas características proprias para Captar Informações e Engajamento dentro da sua empresa, por meio nativo do Android e iOS.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;