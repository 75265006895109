import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Nossos Serviços',
    paragraph: 'Criatividade é só conectar coisas. Quando você pergunta a pessoas criativas como elas fizeram algo, elas geralmente se sentem um pouco culpadas porque elas não fizeram aquilo realmente, elas apenas viram alguma coisa.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/marketing-digital.png')}
                      alt="Features tile icon 01"
                      width={250}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  Marketing Digital
                    </h4>
                  <p className="m-0 text-sm">
                  Nossa especialidade é no Marketing Digital, estamos focado em crescer sua Marca e deixar como referencia na internet, já pensou ser o primeiro do Google no “SEU” segmento de trabalho? Faça um orçamento sem compromisso.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/seo.png')}
                      alt="Features tile icon 02"
                      width={150}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  Otimização SEO
                    </h4>
                  <p className="m-0 text-sm">
                  Otimização SEO (Search Engine Optimization) para posicionar o seu negócio nos primeiros lugares do Google, seja ele um pequeno, médio ou até um grande negócio.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/ecommerce.png')}
                      alt="Features tile icon 03"
                      width={150}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  E-commerce (Loja Virtual)
                    </h4>
                  <p className="m-0 text-sm">
                  Temos soluções completas e profissionais para sua loja virtual. Elaboramos sua loja em diversas com opções de plataforma, opções de pagamento, frete, controle de estoque com suporte completo incluindo a criação de redes sociais.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/sites.png')}
                      alt="Features tile icon 04"
                      width={150}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  Sites
                    </h4>
                  <p className="m-0 text-sm">
                  Criamos o seu site, com as melhores ferramentas e necessidades, são imensas possibilidades de modelos e funcionalidades.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-05.svg')}
                      alt="Features tile icon 05"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  Blogs
                    </h4>
                  <p className="m-0 text-sm">
                  Criamos seu blog, com os melhores designs do mercado, daremos suporte para o usuário no tempo de transição sobre a plataforma, monte seu blog.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-06.svg')}
                      alt="Features tile icon 06"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                  E-mail Marketing
                    </h4>
                  <p className="m-0 text-sm">
                  Criamos o design do e-mail marketing e criamos uma lista de e-mails para envio automático, com data e hora programada para melhor conversão de leads.
                    </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;