import React from 'react'
import ThaisData from './ThaisData'

export default function Thais () {

    return (
        <>
            <div>
                TESTE
            </div>

            <ThaisData />
        </>
    )
}

