import React from 'react'
import FeaturesSplit from '../components/sections/FeaturesSplit'

export default function Contato () {

    return (
        <>
            
            <FeaturesSplit />
        </>
    )
}